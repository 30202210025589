import React from "react";
import styled from "styled-components";
import { color } from "../theme/theme.utils";

const StyledExclusiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid ${color.gold};
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 15%);
  border-radius: 8px;
  background-color: ${color.white};
`;

const StyledExclusiveTitle = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${color.gold};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
`;

const StyledExclusiveContent = styled.div`
  padding: 16px;
  font-size: 1.1rem;
`;

const Exclusive = ({ title, children, ...rest }) => (
  <StyledExclusiveContainer {...rest}>
    <StyledExclusiveTitle>{title}</StyledExclusiveTitle>
    <StyledExclusiveContent>{children}</StyledExclusiveContent>
  </StyledExclusiveContainer>
);

export default Exclusive;
