import React, { useEffect, useState } from "react";
import MainLayout from "../layouts/main-layout";
import PageLocation from "../components/page-location";
import styled from "styled-components";
import Package from "../components/package";
import { color, greaterOrEqual } from "../theme/theme.utils";
import Book1 from "../images/oferta/fotoksiazka1.jpg";
import Book2 from "../images/oferta/fotoksiazka2.png";
import Pendrive1 from "../images/oferta/pendrivedark1.jpg";
import Pendrive2 from "../images/oferta/pendrivedark2.png";
import Pendrive3 from "../images/oferta/pendrive.png";
import Exclusive from "../components/exclusive";
import { graphql, useStaticQuery } from "gatsby";

const StyledTextContainer = styled.div`
  padding: 16px;

  p {
    color: ${color.darkGray};
    margin: 6px 0;
  }

  h3 {
    color: ${color.darkGray};
    font-weight: bold;
    font-size: 1.3rem;
    margin: 16px 0;
  }

  strong {
    color: ${color.gold};
  }

  li {
    color: ${color.darkGray};
    margin-left: 32px;
  }
`;

const StyledOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 36px 0;

  ${greaterOrEqual("xl")} {
    flex-direction: row;
    justify-content: center;
    gap: 8px;
  }
`;
const StyledImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  gap: 16px;

  img {
    width: 100%;
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
  }
`;

const Oferta = () => {
  const [videoPackagesElements, setVideoPackagesElements] = useState();
  const [fotoPackagesElements, setFotoPackagesElements] = useState();
  const {
    swapi: { videoPackages, fotoPackages },
  } = useStaticQuery(
    graphql`
      {
        swapi {
          videoPackages {
            description
            name
            positions
            price
            isPremium
          }
          fotoPackages {
            description
            name
            positions
            price
            isPremium
          }
        }
      }
    `
  );

  useEffect(() => {
    if (videoPackages) {
      const packagesArray = videoPackages.map(
        ({ description, isPremium, name, positions, price }) => (
          <Package
            key={name}
            title={name}
            price={price}
            options={positions}
            height={640}
            description={description}
            style={{ transform: isPremium && "scale(1.07)" }}
            variant={isPremium ? "premium" : ""}
          />
        )
      );

      setVideoPackagesElements(packagesArray);
    }
    if (fotoPackages) {
      const packagesArray = fotoPackages.map(
        ({ description, isPremium, name, positions, price }) => (
          <Package
            key={name}
            title={name}
            price={price}
            options={positions}
            description={description}
            style={{ transform: isPremium && "scale(1.07)" }}
            variant={isPremium ? "premium" : ""}
          />
        )
      );

      setFotoPackagesElements(packagesArray);
    }
  }, [fotoPackages, videoPackages]);
  return (
    <MainLayout>
      <PageLocation pageName="Oferta" url="/oferta" />
      <StyledTextContainer>
        <h2>Wybierz pakiet dla siebie</h2>
        <p>
          Zajmujemy się filmowaniem oraz fotografią w oparciu o
          najnowocześniejsze technologie. W skład naszego sprzętu wchodzą m.in.
          urządzenia stabilizujące czy drony.
        </p>
        <p>Nasze materiały obejrzysz w zakładce portfolio.</p>
        <p>
          Dostosowujemy się do klienta. Nie mamy określonych scenariuszy.
          Stawiamy na pełną swobodę chyba, że klient zechce inaczej.
        </p>
        <p>
          Oferujemy pomoc w tym jakże pełnym emocji dniu, subtelnie nakierowując
          kilka chwil aby materiał pamiątkowy był jeszcze ciekawszy.
        </p>
        <p>
          Cechujemy się przyjazną atmosferą z klientami a tym samym ograniczamy
          do minimum poczucie presji związanym z kamerami i innymi urządzeniami
          rejestrującymi obraz czy dźwięk.
        </p>
      </StyledTextContainer>
      <StyledOfferContainer>{videoPackagesElements}</StyledOfferContainer>
      <StyledTextContainer>
        <h3>Usługi fotograficzne</h3>
        <p>
          Świadczymy również usługi „fotograficzne” a w skład ich pakietów
          wchodzi szereg możliwości – zaczynając od kilku wariantów
          foto-książki, ilości stron, zdjęć, zakresu działań oraz miejsc
          związanych z sesją plenerową etc.
        </p>
        <p>
          <b>
            W związku z powyższym zachęcamy Państwa do ustalania oferty „foto”
            indywidualnie.
          </b>
        </p>
        <h3>
          <strong>W PAKIECIE FOTO + VIDEO MOŻLIWE DODATKOWE RABATY!</strong>
        </h3>
        <h3>Pakiety usług fotograficznych</h3>
      </StyledTextContainer>
      <StyledOfferContainer>{fotoPackagesElements}</StyledOfferContainer>
      <StyledTextContainer>
        <StyledImagesWrapper maxWidth="460">
          <img src={Book1} alt="foto-book" />
          <img src={Book2} alt="foto-book" />
        </StyledImagesWrapper>
        <h3>Informacje dodatkowe:</h3>
        <ul>
          <li>
            Filmowanie rozpoczyna się od przygotowań Państwa młodych (w
            zależności od miejsca – zazwyczaj dwie godziny przed ceremonią w
            kościele), a kończy po oczepinach – nie później niż o godzinie
            pierwszej w nocy
          </li>
          <li>
            w ofercie usługi fotograficzne – oferta ustalana indywidualnie
          </li>
          <li>
            Termin realizacji zamówienia: 90-110 dni roboczych w zależności od
            natężenia sezonu licząc od dnia zarejestrowania wszystkich
            niezbędnych materiałów (w tym sesja plenerowa)
          </li>
        </ul>
        <h3>Do każdego pakietu personalizowany pendrive</h3>
        <StyledImagesWrapper maxWidth="320">
          <img src={Pendrive1} alt="pendrive" />
          <img src={Pendrive2} alt="pendrive" />
          <img src={Pendrive3} alt="pendrive" />
        </StyledImagesWrapper>
        <h3>Opcje dodatkowe:</h3>
        <ul>
          <li>
            PENDRIVE PERSONALIZOWANY „DREWNO” – każda kolejna sztuka poza
            pakietem – 160 zł
          </li>
          <li>
            PENDRIVE STANDARD – kopia filmów na standardowym pendrive
            przeznaczony dla rodziców, świadków etc. – 50 zł
          </li>
          <li>DODATKOWE UJĘCIA Z DRONA W PLENERZE - 200 zł</li>
        </ul>
        <Exclusive title="Oferta EXCLUSIVE" style={{ margin: "64px auto" }}>
          <p>
            Oferta <strong>Exclusive</strong> - uwzględnia ona najwyższy z
            pakietów zarówno filmu jak i fotografii.
          </p>
          <p>
            Całkowita cena usługi wg cennika -
            <span>7000 zł (3800zł + 3200zł)</span>
          </p>
          <p>
            Zamawiająć wariant <strong>Exclusive</strong> obejmujący ten sam
            zakres usług zyskujesz rabat <b>500 zł</b> dzięki czemu za pełen
            pakiet video oraz foto w najwyższych wariantach zapłacisz{" "}
            <b>6500 zł</b>
          </p>
        </Exclusive>
        <h3>
          Dlaczego warto zastanowić się nad pakietem łączonym (foto + video) z
          jednej firmy:
        </h3>
        <ul>
          <li>
            Doświadczony zespół – wiemy jak pracować w zespole. Cechujemy się
            zgraną kooperacją. Rozumiemy się nawzajem. Wiemy gdzie i kiedy się
            ustawić, aby materiał był najciekawszy, a przede wszystkim aby nie
            przeszkadzać Parze Młodej, gościom czy sobie nawzajem.
          </li>
          <li>
            Materiały w tym samym dniu – nie muszą Państwo szukać umów, upominać
            się o terminy czy martwić o materiał. Zarówno film, zdjęcia jak i
            foto-książkę odbierają Państwo w pakiecie tego samego dnia.
          </li>
        </ul>
        <h3>Przypominamy</h3>
        <p className="bold">
          Podane powyżej warianty to przykładowe "szablony". Oferta FOTO
          ustalana jest indywidualnie w oparciu o potrzeby klienta.
        </p>
        <p className="bold">
          Ceny ofert foto podane powyżej nie uwzględniaja możliwego rabatu w
          przypadku pakietu FOTO-VIDEO.
        </p>
        <p className="bold">
          Posiadamy certyfikaty uprawniające do filmowania w obiektach skralnych
          (wymóg wielu księży).
        </p>
        <p className="bold">
          Nie zabezpieczamy materiałów przekazanych klientom, dzięki czemu mają
          Państwo pełne prawo do dalszego kopiowania nośników: dla rodziny,
          gości etc.
        </p>
      </StyledTextContainer>
    </MainLayout>
  );
};

export default Oferta;
