import React from "react";
import styled from "styled-components";
import { color, greaterOrEqual } from "../theme/theme.utils";

const StyledPackageContainer = styled.div`
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  width: 280px;
  border: 1px solid
    ${({ variant }) => (variant === "premium" ? color.gold : color.grey)};
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 15%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${color.white};

  ${greaterOrEqual("xl")} {
    width: 360px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${({ variant }) =>
    variant === "premium" ? color.gold : color.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
`;
const StyledPrice = styled.h2`
  margin: 16px;
  font-size: 3rem;
  font-weight: bold;
`;
const StyledInPackageWrapper = styled.span`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ variant }) =>
    variant === "premium" ? color.gold : color.grey};
  padding: 4px;
  border-radius: 8px;
`;

const StyledPackageOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 16px;
  text-align: center;
  margin-top: 8px;

  p {
    font-size: 0.9rem;
    margin: 0;
  }

  span {
    font-size: 0.85rem;
  }

  hr {
    width: 100%;
    height: 2px;
    background-color: ${color.lightGrey};
    border: none;
  }
`;

const StyledAdditionalDescription = styled.div`
  font-size: 0.7rem;
`;

const Package = ({
  title,
  price,
  variant,
  options = [],
  description,
  height = 430,
  ...rest
}) => {
  const packageOptions = options.map((option) => <p>{option}</p>);

  return (
    <StyledPackageContainer variant={variant} height={height} {...rest}>
      <StyledTitle variant={variant}>{title}</StyledTitle>
      <StyledPrice>{price} zł</StyledPrice>
      <StyledInPackageWrapper variant={variant}>
        W Pakiecie:
      </StyledInPackageWrapper>
      <StyledPackageOptions>
        {packageOptions}
        {description && (
          <>
            <hr />
            <StyledAdditionalDescription>
              {description}
            </StyledAdditionalDescription>
          </>
        )}
      </StyledPackageOptions>
    </StyledPackageContainer>
  );
};

export default Package;
